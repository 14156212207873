// Import React
import React, { useState } from "react";
// Import the CSS module
import styles from "./signInButton.module.css";
import { useSupabaseClient } from "@/hooks/useUser";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import { toast } from "sonner";
import { getBaseAppUrl } from "@/pages/getBaseAppUrl";
import { LuMail } from "react-icons/lu";
import { Button, Dialog, Flex, TextField } from "@radix-ui/themes";

function SignInWithEmail({ redirect }: { redirect?: string }) {
  const supabase = useSupabaseClient();
  const finalRedirect = redirect ?? "/app/home";
  const isWeb = Capacitor.getPlatform() === "web";
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>
        <button className={styles.gsiMaterialButton} style={{ width: "100%" }}>
          <div className={styles.gsiMaterialButtonState}></div>
          <div className={styles.gsiMaterialButtonContentWrapper}>
            <div className={styles.gsiMaterialButtonIcon}>
              <LuMail />
            </div>
            <span className={styles.gsiMaterialButtonContents}>
              Continue with Email
            </span>
            <span style={{ display: "none" }}>Continue with Email</span>
          </div>
        </button>
      </Dialog.Trigger>
      <Dialog.Content>
        <Dialog.Title>Sign in with email</Dialog.Title>
        <Dialog.Description>Enter your email to sign in</Dialog.Description>
        <Flex direction="column" gap="2" my="2">
          <TextField.Root
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            size={"3"}
          ></TextField.Root>
          <Button
            size={"3"}
            onClick={() => {
              supabase.auth
                .signInWithOtp({
                  email: email,
                  options: {
                    emailRedirectTo: isWeb
                      ? `${
                          window.location.origin
                        }/auth/redirect?actualTarget=${encodeURI(
                          window.location.href,
                        )}`
                      : "spellbound://app/home",
                  },
                })
                .then(() => {
                  toast.success("A link to login has been sent to your email", {
                    position: "top-center",
                  });
                  setOpen(false);
                });
            }}
          >
            Send a login link
          </Button>
          <Dialog.Close>
            <Button size={"3"} variant="soft" color="gray">
              Cancel
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

export default SignInWithEmail;
