import Sqids from "sqids";

const referralSquid = new Sqids({
  minLength: 6,
  alphabet: "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890",
});

export function generateReferralCode(userId: number) {
  return referralSquid.encode([userId]);
}

export function decodeReferralCode(code: string) {
  const [userId] = referralSquid.decode(code);
  return userId;
}
