import { Capacitor } from "@capacitor/core";

export function getBaseAppUrl() {
  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`;
  }

  if (process.env.RENDER_INTERNAL_HOSTNAME) {
    return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`;
  }
  if (Capacitor.getPlatform() === "web") {
    return `http://localhost:${process.env.PORT ?? 3000}`;
  } else {
    return `https://quick-treefrog-modest.ngrok-free.app`;
  }
}
