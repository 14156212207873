import { customType } from "drizzle-orm/pg-core";

type Weight = "A" | "B" | "C" | "D" | "E";

function genExpWithWeights(input: [string, Weight][]) {
  const columnExpressions = input.map(([column, weight]) => {
    return `setweight(to_tsvector('english', coalesce(${column}, '')), '${weight}')`;
  });

  return `tsvector GENERATED ALWAYS AS (${columnExpressions.join(
    " || "
  )}) STORED`;
}

export const tsvector = customType<{
  data: string;
  config: { sources: [string, Weight][] };
}>({
  dataType(config) {
    if (config) {
      const sources = config.sources
        .map(([column, _]) => column)
        .join(" || ' ' || ");
      return genExpWithWeights(config.sources);
    } else {
      return `tsvector`;
    }
  },
});
