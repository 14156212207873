import { Box, Button, Dialog, Flex, Link } from "@radix-ui/themes";
import SignInWithGoogle from "@/pages/app/auth/signInWithGoogle";
import SignInWithDiscord from "@/pages/app/auth/signInWithDiscord";
import { ReactNode, useState } from "react";
import SignInWithAppleId from "./signInWithApple";
import { Capacitor } from "@capacitor/core";
import SignInWithEmail from "./signInWithEmail";

export function AuthDialogSignedOutContent({
  redirect,
  title,
  onClose,
}: {
  redirect?: string;
  title?: ReactNode;
  onClose?: () => void;
}) {
  return (
    <Dialog.Content size={"4"} className={"rounded-2xl"}>
      <Dialog.Title size={"4"}>Login or Signup</Dialog.Title>
      <Dialog.Description size="3" mb="4">
        {title ? title : "Pick one of our sign-in options below!"}
      </Dialog.Description>

      <Flex direction="column" gap="5" my={"4"} mx={"4"}>
        <Box className={"w-full"}>
          <SignInWithGoogle redirect={redirect} />
        </Box>
        <Box className={"w-full"}>
          <SignInWithDiscord redirect={redirect} />
        </Box>
        {Capacitor.getPlatform() !== "web" && (
          <Box className={"w-full"}>
            <SignInWithAppleId redirect={redirect} />
          </Box>
        )}
        <Box className={"w-full"}>
          <SignInWithEmail redirect={redirect} />
        </Box>
        <Flex direction="column" gap="2">
          <Link
            href={
              "https://tryspellbound.notion.site/Spellbound-Terms-Of-Service-93ddef119a214547acaacaef2d8323e0?pvs=4"
            }
            target="_blank"
          >
            Our Terms of Service and Privacy Policy
          </Link>
        </Flex>
      </Flex>

      <Flex gap="3" mt="4" mx={"4"} justify="end">
        <Dialog.Close>
          <Button
            variant="surface"
            color="gray"
            size={"4"}
            radius={"full"}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Dialog.Close>
      </Flex>
    </Dialog.Content>
  );
}
