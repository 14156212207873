import { Button, Dialog, Strong } from "@radix-ui/themes";
import { useState } from "react";
import { useUser } from "@/hooks/useUser";
import { AuthDialogSignedOutContent } from "@/pages/app/auth/authDialogSignedOutContent";
import { AuthDialogSignedInContent } from "@/pages/app/auth/authDialogSignedInContent";
import { useUserProfile } from "@/utils/auth/useUserProfile";

export function AuthDialog() {
  const [isOpen, setIsOpen] = useState(false);
  const user = useUser();
  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger>
        <Button
          radius={"full"}
          variant={user ? "outline" : "solid"}
          size={"2"}
          color={"lime"}
          onClick={(event) => setIsOpen((old) => !old)}
        >
          {user ? "Settings" : <Strong>Sign In</Strong>}
        </Button>
      </Dialog.Trigger>

      {user ? (
        <AuthDialogSignedInContent />
      ) : (
        <AuthDialogSignedOutContent onClose={() => setIsOpen(false)} />
      )}
    </Dialog.Root>
  );
}
