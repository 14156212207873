import { useTheme } from "next-themes";
import { Box, Flex, IconButton, TextField, Strong } from "@radix-ui/themes";
import Link from "next/link";
import { SpellboundIcon } from "@/pages/app/home/spellboundIcon";
import { FaDiscord, FaMagnifyingGlass } from "react-icons/fa6";
import { StoryDrawer } from "@/pages/app/home/storyDrawer";
import { AuthDialog } from "@/pages/app/auth/authDialog";
import { FaMoon, FaSun } from "react-icons/fa";
import { useAnalytics } from "@/analyticsContext";

export function GlobalHeader({
  onChange,
  onCheckedChange,
  enableSearch,
  onClickSpellbound,
  lowContrast = false,
  additionalContent,
}: {
  onCheckedChange?: (checked) => void;
  onChange?: (e) => void;
  onClickSpellbound?: () => void;
  enableSearch: boolean;
  lowContrast?: boolean;
  additionalContent?: React.ReactNode;
}) {
  const analytics = useAnalytics();
  return (
    <Flex
      style={{
        width: "100%",
        maxWidth: "100svw",
        position: "relative",
      }}
      direction={"column"}
      align={"center"}
      justify={"center"}
      gap={"3"}
    >
      <Flex
        className={"w-full h-full"}
        justify={"center"}
        align="center"
        direction={"row"}
      >
        <Flex
          asChild
          onClick={() => onClickSpellbound?.()}
          className={
            lowContrast
              ? "hover:opacity-100 opacity-50 transition-opacity"
              : undefined
          }
          align={"center"}
          gap="2"
          style={{
            pointerEvents: "auto",
            zIndex: 10,
            height: "100%",
            cursor: !!onClickSpellbound ? "pointer" : "default",
          }}
        >
          <Link href={"/"}>
            <SpellboundIcon isReponsive={true} />
          </Link>
        </Flex>

        <Flex
          direction={"column"}
          align={"center"}
          width={"100%"}
          justify={"center"}
        >
          <Flex
            align={"center"}
            justify={"center"}
            height={"wrap-content"}
            style={{
              boxSizing: "border-box",
              marginLeft: "auto",
            }}
          >
            {enableSearch && (
              <TextField.Root
                size={"3"}
                className={"min-w-0 hidden sm:flex"}
                onChange={onChange}
                aria-label="Search"
                placeholder="Search"
              >
                <TextField.Slot>
                  <FaMagnifyingGlass aria-hidden="true" />
                </TextField.Slot>
              </TextField.Root>
            )}
            {additionalContent && <Box mx={"2"} />}
            {additionalContent}
            <Box mx={"2"} />
            <StoryDrawer />
            <Box mx={"2"} />
            <AuthDialog />
            <Box mx={"2"} />
            <IconButton
              variant={"outline"}
              size={"2"}
              radius={"full"}
              onClick={() => {
                analytics.capture("ui.discordClicked", {});
                window.open("https://discord.gg/spellbound", "_blank");
              }}
            >
              <FaDiscord />
            </IconButton>
          </Flex>
        </Flex>
      </Flex>
      {enableSearch && (
        <TextField.Root
          size={"3"}
          className={"visible sm:hidden ml-auto mr-auto w-full"}
          onChange={onChange}
        >
          <TextField.Slot>
            <FaMagnifyingGlass />
          </TextField.Slot>
        </TextField.Root>
      )}
    </Flex>
  );
}
