import { Flex, Heading } from "@radix-ui/themes";
import StarsIcon from "../../../../public/icons/stars.svg";

export function SpellboundIcon({
  color = "var(--yellow-11)",
  isReponsive = false,
}: {
  color?: string;
  isReponsive?: boolean;
}) {
  return (
    <Flex
      style={{
        height: "100%",
        paddingRight: "-20px",
      }}
    >
      <Heading
        size={{ initial: "6", sm: "7" }}
        className={
          isReponsive
            ? "rt-r-display-none sm:rt-r-display-block"
            : "rt-r-display-block"
        }
      >
        spellbound
      </Heading>
      <Heading
        size={"6"}
        className={
          isReponsive
            ? "rt-r-display-block sm:rt-r-display-none"
            : "rt-r-display-none"
        }
      >
        S
      </Heading>
      <div style={{ color: color }}>
        <StarsIcon className={"w-5 h-5"} alt={"Stars Icon"}></StarsIcon>
      </div>
    </Flex>
  );
}
