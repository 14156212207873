import {
  Button,
  Dialog,
  Flex,
  Select,
  Spinner,
  Switch,
  Separator,
  Text,
  Heading,
  Box,
  Strong,
} from "@radix-ui/themes";
import { useUserProfile } from "@/utils/auth/useUserProfile";
import { trpc } from "@/utils/trpc";
import { FontType, UiThemeType } from "@/db/enumTypes";
import { fontEnum, themeEnum } from "@/db/schema";
import { useEffect, useState } from "react";
import { SubscriptionDialog } from "../story/[conversationId]/subscriptionDialog";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { useSupabaseClient, useUser } from "@/hooks/useUser";
import { ReferralMessage } from "../home/referralMessage";
import { useAnalytics } from "@/analyticsContext";
import { useTheme } from "next-themes";
import { FaMoon, FaSun } from "react-icons/fa6";

function NewComponent({
  blockSensitiveContent,
  onRequestedOff,
}: {
  blockSensitiveContent: boolean;
  onRequestedOff: () => Promise<void>;
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>
        <Switch checked={blockSensitiveContent ?? false} />
      </Dialog.Trigger>
      <Dialog.Content>
        <Dialog.Title>Block sensitive content</Dialog.Title>
        <Dialog.Description>
          By default Spellbound hides content that may be considered sensitive.
          <br />
          <br />
          If you turn off this setting, you may see content that is not suitable
          for all users.
          <br />
          <br />
          We recommend keeping this setting <Strong>On</Strong>
        </Dialog.Description>
        <Flex direction={"column"} gap="3">
          <Text></Text>
          <Button
            onClick={async () => {
              setLoading(true);
              await onRequestedOff();
              setLoading(false);
              setOpen(false);
            }}
            variant="surface"
            size={"4"}
            color="red"
            loading={loading}
            radius={"full"}
          >
            Turn Off
          </Button>
          <Dialog.Close>
            <Button variant="surface" color="gray" size={"4"} radius={"full"}>
              Close
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

function Content() {
  const { userProfile, refetch } = useUserProfile();
  const isSubscribed = userProfile?.subscriptionTier !== "basic";
  const updateUserTheme = trpc.users.updateTheme.useMutation();
  const updatePreferredFont = trpc.users.updatePreferredFont.useMutation();
  const allowAdult = trpc.users.updateAllowAdult.useMutation();
  const blockSensitiveContent = !userProfile?.allowAdult;
  const analytics = useAnalytics();
  const theme = useTheme();
  return (
    <Flex direction="column" my="4" gap="3">
      <Flex direction="column" my="4" gap="3">
        <Flex align={"center"} gap="2">
          <Heading>Account Settings</Heading>
          {(updateUserTheme.isPending || allowAdult.isPending) && <Spinner />}
        </Flex>
        <Flex direction={"row"}>
          <Button
            variant={"outline"}
            size={"2"}
            radius={"full"}
            onClick={() => {
              const newTheme = theme.theme == "dark" ? "light" : "dark";
              analytics.capture("ui.toggleDarkMode", {
                selected: newTheme,
              });
              return theme.setTheme(newTheme);
            }}
            aria-label={`Switch to ${
              theme.theme === "dark" ? "light" : "dark"
            } mode`}
          >
            {theme.theme !== "dark" ? (
              <span className="flex items-center gap-2">
                <FaSun aria-hidden="true" /> Switch to Dark Mode
              </span>
            ) : (
              <span className="flex items-center gap-2">
                <FaMoon aria-hidden="true" /> Switch to Light Mode
              </span>
            )}
          </Button>
        </Flex>
        <Flex direction="row" gap="2" align={"center"}>
          <Text>Change your font</Text>
          <Select.Root
            disabled={!isSubscribed}
            value={userProfile?.preferredFont}
            onValueChange={async (value: string) => {
              await updatePreferredFont.mutateAsync({
                preferredFont: value as FontType,
              });
              refetch();
            }}
          >
            <Flex>
              <Select.Trigger mr="auto" />
              <Spinner loading={updatePreferredFont.isPending} />
            </Flex>
            <Select.Content>
              {fontEnum.enumValues.map((theme) => (
                <Select.Item key={theme} value={theme}>
                  {theme}
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
        </Flex>
        <Flex direction="row" gap="2" align={"center"}>
          <Text>Block sensitive content</Text>
          {blockSensitiveContent ? (
            <NewComponent
              blockSensitiveContent={blockSensitiveContent}
              onRequestedOff={async () => {
                await allowAdult.mutateAsync({
                  allowAdult: true,
                });
                refetch();
              }}
            />
          ) : (
            <Switch
              checked={blockSensitiveContent ?? false}
              onCheckedChange={async (value: boolean) => {
                await allowAdult.mutateAsync({
                  allowAdult: !value,
                });
                refetch();
              }}
            />
          )}
        </Flex>
      </Flex>
      <Flex direction="column" my="4" gap="3">
        <Heading>Subscribers only</Heading>
        <Flex
          direction={"column"}
          gap="3"
          style={{ opacity: isSubscribed ? 1 : 0.7 }}
        >
          <Flex direction="row" gap="2" align={"center"}>
            <Text>
              <Strong>Check your story settings for 'Control Mode'</Strong>
            </Text>
          </Flex>
          <Flex direction="row" gap="2" align={"center"}>
            <Text>Change your theme</Text>
            <Select.Root
              disabled={!isSubscribed}
              value={userProfile?.preferredTheme}
              onValueChange={async (value: string) => {
                await updateUserTheme.mutateAsync({
                  theme: value as UiThemeType,
                });
                refetch();
              }}
            >
              <Flex>
                <Select.Trigger mr="auto" />
                <Spinner loading={updateUserTheme.isPending} />
              </Flex>
              <Select.Content>
                {themeEnum.enumValues.map((theme) => (
                  <Select.Item key={theme} value={theme}>
                    {theme}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>
          </Flex>
        </Flex>
      </Flex>

      {userProfile && <ReferralMessage userProfile={userProfile} />}
    </Flex>
  );
}

export function AuthDialogSignedInContent() {
  const supabase = useSupabaseClient();
  const user = useUser();
  const { userProfile } = useUserProfile();

  return (
    <Dialog.Content size={"4"} className={"rounded-2xl"}>
      <Dialog.Title size={"4"}>Account Settings</Dialog.Title>
      <Dialog.Description size="3" mb="4">
        You're currently signed in as {user?.email}
        <br />
        <br />
        You have {userProfile?.quills} quills remaining
      </Dialog.Description>

      <Content />
      <SubscriptionDialog
        trigger={
          <Button variant="surface" m="0">
            <Text size={"2"}>Manage Your Quills</Text>
          </Button>
        }
      />
      <Flex gap="3" mt="4" mx={"4"} justify="end">
        <Dialog.Close>
          <Flex gap={"4"}>
            <Button
              variant="surface"
              color="red"
              size={"4"}
              radius={"full"}
              onClick={() => {
                supabase.auth
                  .signOut()
                  .then(() => {
                    window.location.reload();
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              }}
            >
              Logout
            </Button>
            <Button variant="surface" color="gray" size={"4"} radius={"full"}>
              Close
            </Button>
          </Flex>
        </Dialog.Close>
      </Flex>
    </Dialog.Content>
  );
}
